"use client";
import { SessionProvider } from "next-auth/react";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}
function Provider({ children }: Props) {
  const sessionBasePath = process.env.NEXTAUTH_URL;

  return (
    <SessionProvider basePath={sessionBasePath}>{children}</SessionProvider>
  );
}


export default Provider;
